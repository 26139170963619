import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO
      title="About everyday domesticity"
      description="The Doméstico Perverso (Perverse Domesticity) series by artist Katharina
        Welper is embedded in the plastic-visual investigation of this universe
        of issues."
    />
    <article className="article">
      <h1>About everyday domesticity</h1>
      <p className="author">Ivair Reinaldim</p>
      <p>
        It is usual for us to associate the domestic world to both what is
        familiar and to a certain idea of comfort and coziness. But at the same
        time, we also know that it is exactly in the realm of the home, in the
        private world, that many of the situations involving both physical and
        psychological violence against people occur. The intimacy resulting from
        a certain domesticity – and here it is relevant to think of it in terms
        of domestication and of the processes inherent to that condition – ends
        up being one of the ways in which both discomfort and helplessness make
        themselves present in human existence.
      </p>
      <p>
        The Doméstico Perverso (Perverse Domesticity) series by artist Katharina
        Welper is embedded in the plastic-visual investigation of this universe
        of issues. Starting from a group of floral-themed embroideries, based on
        Eastern European patterns, created by the artist on sheets and other
        pieces belonging to an old trousseau of a German nurse (monogrammed with
        the initials E. B.), the works that constitute this series seem to
        reconfigure some rather decoded contemporaneous works grounded on the
        craft of embroidery: the feminine universe, the finery and ornamentation
        of motifs, the domesticity of handicraft. Not that Welper’s works cannot
        be understood on the basis of this interpretation; they can; however,
        they are not limited by it.
      </p>
       
      <p>
        In this sense, the idea of perversion present in the title of the series
        performs two essential functions. On the one hand, on the level of
        discourse, the idea of perversion, more than a mere allusion to a
        concept, is also an action. Not only does it represent the idea, but it
        also awards a political sense to these works. That is, they refer, in a
        metaphorical way, to the small and great perversions practiced in the
        realm of domesticity, to the situations experienced but not always
        shared beyond the realm of what is private – be this privacy the literal
        world of the home, of family life, but also of the subjectivity of each
        individual. This domesticity, then, becomes the locus of the secret, the
        hidden, the unspoken, the unfamiliar.
      </p>
       
      <p>
        On the other hand, this perversion aims at corrupting, at altering a
        preexisting condition anchored in a certain social condition of
        consensual normalcy. It represents a deviation of norms. In practical
        terms, Katharina Welper’s act of embroidering subverts the inherent
        mechanisms of the technique, as it is no longer carried out on a smooth
        blank surface. Although the frontality of the work reinforces the
        linearity of the motifs embroidered by the artist, a more attentive look
        at the material allows one to apprehend the folds and creases, the
        reconfiguration of its base-form, as if the action of the artist meant
        to highlight a new visual form without erasing the marks and stories
        present in the original material. The presence of another, older
        embroidery, in the same shade as the material itself, brings to life the
        difference not only of the structural aspects of the new embroidery, but
        also of the manner in which it is executed.
      </p>
       
      <p>
        It is of particular interest to us here to reflect on how the act of the
        artist is closely related to the material she has chosen as her basis.
        The material sewn and prepared to make up the trousseau (sheets,
        pillowcases, towels, etc), used and worn off during its use or even by
        the mere passage of time, marked by unknown memories, had its existence
        linked to the domestic, to the private, but now, re-signified as works
        by the artist, emerges in a subverted form from a new, public existence.
        The finery and dexterity of the new embroidery suggest the covering of
        these folds and creases created by time. However, more than hiding, they
        highlight the contradiction inherent to this process.
      </p>
      <p>
        It is essential here to re-think the domesticity of both these materials
        linked to the realm of the home and the practice of embroidery – though
        there is today a condition of industrial production that co-exists with
        the persistence of the manual craft. The act of doing reinforces the act
        of affording visibility to the process of making something domestic
        (domesticating). These works highlight the impossibility of appearances
        eliminating subjacent layers and processes, conflicts and contradictions
        coming from it. There is, after all, something in common – in the sense
        of community – in its singularity.
      </p>
    </article>
  </Layout>
);

export default IndexPage;
